* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h2 {
}

body {
  /* background: #fbfbfb !important; */
  font-family: "DM Sans", sans-serif !important;
  background: #f4f7fe !important;
}

.form-select:focus {
  border-color: #dee2e6 !important;
  outline: 0;
  box-shadow: none !important;
}

.loader-style {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #ffffff47;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 9;
  flex-direction: column;
}


.login-box-inner-wrap h2 {
  color: #2b3674;

  font-size: 30px;
  font-weight: 700;

  line-height: 32px;
  letter-spacing: -0.6px;
}
.form-set {
  display: flex;
  height: 164px;
  flex-direction: column;
  justify-content: center;
}
.login-cmn-box {
  margin: auto;
  max-width: 750px;
}
.login-box-inner-wrap {
  max-width: 500px;
  margin: auto;
  padding: 45px 0;
  min-height: 450px;
}
.login-box-inner-wrap form {
  margin-top: 35px;
}
.login-box-inner-wrap label {
  color: #2b3674;
  font-size: 14px;
  font-weight: 800;
  line-height: 15px;
  letter-spacing: -0.3px;
}
::placeholder {
  color: #979797 !important;
}
.form-set input {
  background: #fff;
  height: 42px;
  font-size: 13px;
  color: #000;
  border-radius: 16px;
  border: 1px solid var(--Secondary-Grey-500, #e0e5f2);
}

.login-box-inner-wrap input::placeholder {
  color: #071523;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px; /* 120% */
  letter-spacing: 0.3px;
}
.submit {
  margin-top: 30px !important;
  border: 1px solid #4318ff;
  background: #4318ff;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  color: #fff;
  text-align: center;

  padding: 25px !important;
  font-size: 15px;
  border-radius: 16px;
  font-weight: 400;
  width: 100%;
  height: 42px;
  text-decoration: none;
  margin: auto;
}
.forgot {
  color: #4318ff;
  text-align: center;

  font-size: 15px;
  font-style: normal;
  text-decoration: none;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: block;
  margin-top: 20px;
}
.login-box-inner-wrap input:focus {
  box-shadow: none;
}
.logo {
  margin-left: 90px;
  margin-top: 20px;
}
.box-center.row {
  height: calc(100vh - 240px);
  align-items: center;
}

aside {
  border-right: 1px solid #d9d9d9;
  background: #fff;
  width: 270px;
  height: 100vh;
  position: fixed;
  font-family: "Raleway", sans-serif !important;
  left: 0;
  z-index: 1;
}

.logo-side img {
  width: 160px;
  margin: 20px 55px;
}

.side-menu a {
  color: #a3aed0;
  display: flex !important;

  font-size: 15px;
  padding: 10px 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  align-items: center;
}
.side-menu a svg {
  margin-right: 13px;
  width: 17px;
}
.side-menu a p {
  margin: 0;
}
/* .sidebar {
    padding: 0 30px;
} */

.side-menu .side-btm-space {
  margin-bottom: 5px;
}
.side-menu .side-btm-last {
  margin-top: 40px;
}

.main-wrap {
  display: flex;
  height: 100%;
}
.height-set {
  height: 100%;
}

.right-side-cmn {
  width: calc(100% - 270px);
  position: relative;
  margin-left: auto;
  background: #f4f7fe;
  height: 100vh;
  padding-left: 15px;
}

.right-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading-top h2 {
  margin: 0;
  color: #2b3674;
  font-size: 34px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.68px;
}
.heading-top {
  padding: 25px 12px;
  font-family: "Raleway", sans-serif !important;
}
.right-top hr {
  color: rgba(255, 255, 255, 0.2);
  margin: 1px !important;
  opacity: 1;
}

.cmn-top-fields {
  padding: 20px 7px;
}
.cmn-top-fields input {
  background: #fff;
  border-radius: 0;
  font-size: 14px;
  height: 40px;
  padding-left: 20px;
}
.cmn-top-fields input::placeholder {
  color: #000;

  font-size: 14px;

  font-weight: 400;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
}
.cmn-top-fields input:focus {
  box-shadow: none;
  border: 1px solid #c1dece;
}
.table-cmn tr td p {
  margin: 0;
  color: var(--Secondary-Grey-900, #2b3674);
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.28px;
}
.cmn-top-fields select:focus {
  box-shadow: none;
  border: 1px solid #c1dece;
}
.cmn-top-fields select {
  color: #000;

  height: 40px;
  max-width: 270px;
  padding-left: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  border-radius: 0;
}
.cmn-btn a {
  border: 1px solid #071523;
  background: #071523;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
  color: #fff;
  text-align: center;

  font-size: 13px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 33px;
}
.product-tab ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.side-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 170px);
  margin-top: 10px;
}
.product-tab ul li {
  list-style: none;
  padding: 0;
}
.product-tab {
  padding: 12px 10px;
}
.product-tab ul li a {
  color: rgba(7, 21, 35, 0.5);

  font-size: 13px;
  text-transform: uppercase;
  position: relative;
  padding: 15px 0px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-decoration: none;
  margin-right: 35px;
}
.product-tab .active-tab::before {
  content: "";
  position: absolute;
  bottom: 0;
  background: #071523;
  width: 100%;
  height: 2px;
}
.product-tab .active-tab {
  color: #071523 !important;
}
.product-tab ul li a span {
  margin-left: 10px;
}
.show-results {
  color: #000;

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.select-result span {
  color: #000;

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: block;
  padding-right: 20px;
}
.select-result select {
  padding: 4px 10px;
  width: 55px;
  border: 0.5px solid #d8d7ce;
  background: #fff;
  border-radius: 0;
  color: #000;

  font-size: 13px;
  background-repeat: no-repeat;
  background-position: center right 3px;
  font-weight: 400;
  background-image: url("../Images/Expand_down.svg");
  letter-spacing: -0.3px;
}
.select-result select:focus {
  box-shadow: none;
  border: 1px solid #c1dece;
}
.results-sec {
  padding: 20px 30px;
}
.table-cmn tr th {
  padding: 12px 12px;
  color: #a3aed0;

  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  border-style: solid;
  border-color: #e9edf7;
}
.table-cmn tr {
  border-style: none;
  border-color: #fff;
}

.table-cmn tr td {
  padding: 8px 12px;
  color: #2b3674;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: left;
}
.table-cmn tr td a {
  color: #2b3674;

  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.table-cmn tr th:first-child {
  padding-left: 23px;
}
.table-cmn tr td:first-child {
  padding-left: 23px;
}
.next-btn-fix {
  background: #fff;
  text-align: end;
  position: fixed;
  width: calc(100% - 270px);
  padding: 20px 40px;
  bottom: 0;
  border-top: 1px solid #e9edf7;
}
.next-btn-fix a {
  padding: 7px 35px;
  border: 1px solid #071523;
  background: #071523;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
  color: #fff !important;

  font-size: 15px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-decoration: none;
}
.back-white {
  background-color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.inner-tab-cmn a {
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #40413a;
  text-align: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 10px 25px;
  margin-left: 15px;
}
.inner-tab-cmn .active {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
}

.transactions-box {
  margin-top: 16px;
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 20px 30px;
}
.transactions-box h4 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0;
  letter-spacing: -0.3px;
}
.transactions-box h2 {
  color: #071523;

  font-size: 24px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
  margin-top: 12px;
}
.transaction-graph img {
  width: 100%;
  height: 224px;
}
.transaction-graph {
  margin-top: 15px;
}
.graph-months {
  display: flex;
  justify-content: space-between;
  max-width: 340px;
  margin: auto;
}
.graph-months h5 {
  color: rgba(65, 65, 65, 0.4);
  font-size: 12.171px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.transaction-box-wrap {
  padding: 0 10px;
}

.dashbox-inner-wrap {
  border: 1px solid #6a6d60;
  background: #fff;
  padding: 25px 25px;
}

.dashbox-inner-wrap h2 {
  color: #6a6d60;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.4px;
}

.dashbox-inner-wrap select.form-select {
  border: 1px solid #b9b5ab;
  background: #fff;
  color: #000;

  height: 40px;
  width: 200px;
  padding-left: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  border-radius: 0;
  background-image: url("../Images/Expand_down.svg");
  background-repeat: no-repeat;
  background-position: center right 10px;
}
.dashbox-inner-wrap select.form-select:focus {
  box-shadow: none;
}
.dash-inner-boxes {
  background: #f0efec;
  box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 15%);
  padding: 25px 22px;
  margin-top: 10px;
  min-height: 160px;
}
.dash-inner-boxes h6 {
  color: #071523;

  text-transform: uppercase;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.dash-inner-boxes h4 {
  color: #071523;

  font-size: 24px;
  font-style: normal;
  margin-top: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
}
.dash-inner-boxes p {
  color: #979797 !important;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 0;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.dashbox-side {
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 25px 25px;
  height: 100%;
}
.dashbox-side h2 {
  color: #071523;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 56px;
  letter-spacing: -0.4px;
}

.dashboard-items {
  margin: 30px 0 10px 10px;
}

.dash-graph img {
  width: 100%;
  height: 500px;
}
.dash-bottom {
  margin-top: 30px !important;
}
.table-cmn.table.table-sm {
  margin-bottom: 64px;
  font-family: "DM Sans", sans-serif;
}
.side-menu a:hover {
  background: #a3aed033;
}

.side-menu a.bar_active {
  /* background: #a3aed033; */
  color: #2b3674;
  font-size: 15px;
  display: flex;
  align-items: center;

  border-right: 3px solid #4318ff;
}

.heading-top-inner {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 30px 20px 30px 20px;
}
.heading-top-inner h2 {
  margin: 0;
  color: #000;

  font-size: 30px;
  font-weight: 700;

  line-height: 32px;
  letter-spacing: -0.6px;
}
.heading-top-inner p {
  margin: 0;
  color: #071523;

  font-size: 13px;
  font-style: normal;
  margin-top: 3px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.heading-top-inner h4 {
  color: #071523;
  text-align: right;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.3px;
  margin: 0;
}
.heading-top-inner h2 {
  margin: 0;
}

.product-tab-cmn-btns .buttons {
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #40413a;

  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  padding: 10px 25px;
  margin-left: 15px;
}

.product-tab-cmn-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  margin-right: 8px;
}
.product-tab-left a {
  color: #40413a;

  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}
.product-tab-left a svg {
  margin-right: 10px;
}
.product-cmn-tab {
  margin: 10px 0;
}
.product-tab-cmn-btns .active {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
}
.product-cont-boxes {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 15px 20px;
}
.product-cont-boxes h6 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.product-cont-boxes h2 {
  color: #071523;

  font-size: 26px;
  margin-top: 10px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
}
.product-cont-boxes {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 20px 35px;
  margin-bottom: 20px;
}

.product-graph img {
  width: 100%;
}
.product-table-top {
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 15px 0;
  margin-top: 20px;
}
.product-table-top h3 {
  color: rgba(32, 33, 36, 0.69);
  text-align: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}
.table-cmn .total-amount {
  color: #3eaf3f;
  font-weight: 600;
}
.table-cmn .balance {
  color: #000;
  font-weight: 600;
}
h2.property {
  font-weight: 700;
}

.product-overview-box {
  padding: 30px 50px;
  background: #fff;
  border-radius: 20px;
}

.product-overview-box h2 {
  margin-bottom: 30px;
  color: #2b3674;
  font-size: 16px;
  font-weight: 600;
}
.product-overview-bottom h3 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  letter-spacing: -0.3px;
}
.product-overview-bottom p {
  color: #071523;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin: 0;
}
.bottom-down button {
  color: #071523;
  text-align: right;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  background: none;
  border: none;
}
.bottom-top button {
  color: #071523;
  text-align: right;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  background: none;
  border: none;
}
.product-overview-box .edit-product {
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  background: #071523;
  display: block;
  width: 100%;
  color: #fff;
  text-align: center;

  font-size: 13px;
  margin-top: 8px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  border: none;
  padding: 10px 0;
}
.product-overview-bottom .bottom-top {
  margin-bottom: 15px;
}
.product-overview-bottom .bottom-down {
  margin-bottom: 15px;
}
.product-cmn-color {
  background: #d9d9d9;
}

.product-cmn-color hr {
  color: #e9edf7;
  margin: 1px !important;
  opacity: 1;
}
.table-spacing-between {
  width: 500px;
  max-width: 100%;
}

.transact-amount h3 {
  color: rgba(32, 33, 36, 0.69);

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  letter-spacing: -0.3px;
}
.transact-amount span {
  color: rgba(32, 33, 36, 0.69);

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-left: 6px;
}
.product-transactions .transact-amount {
  display: flex;
}
.product-transactions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 10px;
}
.product-transactions .transact-amount:nth-child(2) {
  margin-left: 30px;
}

.heading-top-inner h2 img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 67px;
  margin-right: 10px;
}
.customer-form {
  padding: 30px 50px;
  margin-bottom: 30px;
  border-radius: 20px;
  background: #fff;
}
.customer-form h2 {
  margin-bottom: 25px;
  color: #2b3674;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.32px;
}
.customer-form label {
  color: #000;

  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.customer-form input {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 10px 10px;
  color: #071523;
  font-size: 13px;
  border-radius: 0;
}
.customer-form input::placeholder {
  color: #071523;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px; /* 120% */
  letter-spacing: 0.3px;
}
.customer-form input:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}

.profile-products-sec {
  padding: 30px 0;
  margin-bottom: 30px;
  border-radius: 20px;
  background: #fff;
}
.profile-products-sec h2 {
  margin-bottom: 25px;
  margin-left: 50px;
  color: #2b3674;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.32px;
}
.product-inner h3 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}
.product-inner p {
  color: #071523;

  font-size: 15px;
  font-weight: 400;
  margin-top: 5px !important;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin: 0;
}

.product-inner-right button {
  color: #000;
  text-align: center;

  font-size: 12px;
  font-weight: 400;
  border: none;
  padding: 4px 50px;
  background: #d9d9d9;
}
.product-inner-right p {
  color: #071523;
  text-align: right;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-top: 10px;
  margin-bottom: 0;
}
.product-main-box {
  padding: 0 50px;
}
.product-inner-right {
  text-align: end;
}
.product-inner-right .closed-btn {
  background-color: #000;
  color: #fff;
  font-weight: 300;
}
.admin-box-top h3 {
  text-align: right;
  color: #2b3674;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.32px;
}
.admin-box-inner p {
  color: var(--Secondary-Grey-600, #a3aed0);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.admin-box-main {
  padding: 0 50px;
}
.admin-box-top h3 span {
  margin: 0 10px;
}

.admin-box-inner textarea {
  border: 0.25px solid #d9d9d9;
  border-radius: 0;
  color: #071523;
  font-size: 13px;
  font-weight: 400;
}
.admin-box-inner textarea:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}
.admin-box-inner textarea::placeholder {
  color: #071523;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px; /* 120% */
  letter-spacing: 0.3px;
}
.admin-box-main hr {
  margin: 20px 0;
}

.product-actions {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 0;
  margin-bottom: 30px;
}
.product-actions h2 {
  color: #071523;

  font-size: 13px;
  padding-left: 70px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}
.product-action-inner {
  padding: 0 50px;
}
.product-action-inner h3 {
  color: #000;

  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin: 0;
}
.product-action-inner p {
  color: #000;
  text-align: right;

  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.24px;
}
.product-actions .add-btn {
  margin-right: 50px;
  border: 1px solid #d9d9d9;
  background: #5f6368;
  color: #fff;

  font-size: 13px;
  font-weight: 400;
  padding: 10px 30px;
}

.cmn-border {
  color: #dadfdd;

  margin: 1px !important;
  opacity: 1;
}
.cmn-background {
  background: #d9d9d9;
}

.table-cmn .total-amount-withdraw {
  color: #e14942;
  font-weight: 600;
}

.form-save-btn button {
  border: 1px solid #d9d9d9;
  background: #5f6368;
  color: #fff;

  padding: 11px 58px;
  font-size: 13px;
  font-weight: 400;
}

.customer-form-new h2 {
  color: #6a6d60;

  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 25px;
  padding: 0 50px;
}

.customer-form-inner {
  background: #fff;
  padding: 30px 50px;
  margin-bottom: 30px;
}
.customer-form-new {
  background-color: #fff;
  border: 1px solid #979797;
}
.customer-form-new label {
  color: #6a6d60;

  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.customer-form-new input {
  background: #fff;
  padding: 10px 10px;
  color: #6a6d60;
  font-size: 13px;
  border-radius: 0;
  border-radius: 16px;
  border: 1px solid var(--Secondary-Grey-500, #e0e5f2);
  height: 50px;
}
.customer-form-new input::placeholder {
  color: #071523;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px; /* 120% */
  letter-spacing: 0.3px;
}
.customer-form-new input:focus {
  box-shadow: none;
  border: 1px solid #6a6d60;
}
.customer-form-new select {
  border: 1px solid #6a6d60;
  background: #fff;
  padding: 10px 10px;
  color: #071523;
  font-size: 13px;
  border-radius: 0;
}
.customer-form-new select:focus {
  box-shadow: none;
  border: 1px solid #c1dece;
}
.form-save-btn-new button {
  border: 1px solid #d9d9d9;
  background: #5f6368;
  color: #fff;

  padding: 8px 70px;
  font-size: 13px;
  font-weight: 400;
}

.product-overview-box .edit-product-new {
  background: #fff;
  display: block;
  width: 100%;
  color: #071523;
  text-align: center;

  font-size: 13px;
  margin-top: 8px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  border: none;
  padding: 10px 0;
  border: 0.25px solid #d9d9d9;
}

.member-info-inner h2 {
  margin-bottom: 10px;
}
.member-inner p {
  margin-bottom: 4px;
  letter-spacing: -0.3px;
  color: var(--Secondary-Grey-600, #a3aed0);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.address-main p {
  margin-bottom: 4px;
  letter-spacing: -0.3px;
  color: var(--Secondary-Grey-600, #a3aed0);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.member-inner p:first-child span {
  color: #1462d7;
  font-weight: 500;
  text-decoration: underline;
  letter-spacing: -0.3px;
}
.product-overview-box select {
  border: 0.25px solid #d9d9d9;
  border-radius: 0;
  color: #071523;
  text-align: center;

  font-size: 13px;
  font-weight: 700;
  padding: 8px;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
}
.product-overview-box select:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}
.cmn-btn button {
  background: #d200a5;
  border: 0;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  color: #fff;
  text-align: center;

  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 10px 65px;
  border-radius: 20px;
}
.product-action-inner h3 a {
  color: #1462d7;
}

.access-select-box {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  margin-bottom: 8px;
  align-items: center;
}
.access-select-box h3 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
}
.access-select-box .inner h3 {
  margin-left: 30px;
  width: 23px;
}
.access-select-box .form-check-inline {
  margin-right: 0;
  margin-left: 30px;
}
.access-select-box p {
  margin-bottom: 0;
  color: #000;

  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.access-select-box .form-check-input {
  border-radius: 5px !important;
  border: 1px solid #979797;
  background: #fff;
}
.access-select-box .form-check-input:checked {
  border-radius: 5px;
  border: 1px solid #979797;
  background: #979797;
}
.input-image-show {
  border: 0.25px dashed #979797;
  background: #fff;
  width: 100%;
  height: 133px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.input-image-show p {
  color: #071523;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-bottom: 0;
}
.input-image-show input {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
}

.blog-save-btn {
  text-align: center;
  margin-top: 44px;
}
.blog-save-btn button {
  padding: 12px 65px;
}

.product-overview-box.blog-bottom {
  padding-bottom: 50px;
}
.check-box-inner {
  display: flex;
  margin-right: 20px;
  align-items: center;
}
.check-box-inner form {
  margin-left: 10px;
}
.check-box-inner form input {
  border-radius: 5px !important;
  border: 1px solid #97979785;
  background: #fff;
  width: 20px;
  height: 20px;
}
.check-box-inner form input:checked {
  border-radius: 5px;
  border: 1px solid #979797;
  background: #979797;
}
.create-mail-checks {
  display: flex;
  align-items: center;
}

.customer-form-inner textarea {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 10px 10px;
  color: #071523;
  font-size: 13px;
  border-radius: 0;
}
.customer-form-inner textarea:focus {
  box-shadow: none;
  border: 0.25px solid #d9d9d9;
}
.dash-graph.right {
  margin-right: 12px;
}

.user-img img {
  width: 50px;
  border-radius: 70px;
  height: 50px;
  object-fit: cover;
}
.select-box select {
  width: fit-content;
  border: 1px solid #dee2e6;
  border-radius: 0;
  color: #000;

  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.select-box select:focus {
  box-shadow: none;
  border: 1px solid #dee2e6;
}

.order-btn a {
  border: 1px solid #979797;
  background: #fff;
  color: #979797 !important;
  width: 85px;
  font-size: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.accept-reject-btn button {
  background: linear-gradient(180deg, #1ba97d, #118d67);
  border: 1px solid #1ba97d;
  box-shadow: 0 10px 14px 0 #1ba97d66;
  color: #fff;
  text-align: center;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 90px;
  border-radius: 0;
  margin: 0 10px;
}
.accept-reject-btn {
  display: flex;
  justify-content: center;
}

.admin-box-select select {
  border-radius: 0;
  color: #6a6d60;

  font-weight: 600;
  font-size: 14px;
  height: 40px;
  letter-spacing: -0.3px;
  line-height: 24px;
  /* max-width: 270px; */
  padding-left: 20px;
}
.customer-form-inner textarea::placeholder {
  color: #6a6d60;
  font-size: 13px;
  font-weight: 600;
  font-size: 14px;
}
.customer-form-new input input::placeholder {
  color: #6a6d60;
  font-size: 13px;
  font-weight: 600;
  font-size: 14px;
}
.customer-form-inner .admin-box-select select {
  appearance: auto;
}

.admin-box-select select:focus {
  box-shadow: none;
}

.account-btn {
  border: 1px solid #979797 !important;
  background: #fff !important;
  color: #979797 !important;
  color: #fff;
  text-align: center;

  margin: 0 2px !important;
  font-size: 13px !important;
  font-style: normal;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  line-height: normal;
  text-decoration: none;
  padding: 8px 33px !important;
  border-radius: 0 !important;
  margin: 10px 0;
}

.account-btn:hover {
  border: 1px solid #979797;
  background: #fff;
  color: #979797 !important;
}

.theme-colr-btn:hover {
  color: #ffffff !important;
}

.common-colr-btn:hover {
  color: #ffffff !important;
}

.add-btn {
  border: 1px solid #071523;
  background: #071523;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  color: #fff;
  text-align: center;

  font-size: 13px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 70px;
}

.customer-form-inner-new {
  border: 0.25px solid #d9d9d9;
  padding: 20px 20px;
}

.support-chat-box-main {
  padding: 22px;
  word-wrap: break-word;
  background-clip: initial;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
}

.user-top-hdng {
  display: flex;
  align-items: center;
}
.user-top-hdng img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-right: 10px;
}
.user-top-hdng h3 {
  font-size: 22px;
  text-transform: capitalize;
  margin-right: 11px;
}

.user-top-hdng h4 {
  font-size: 11px;
  margin: 0;
}

.user-msg-box {
  background: #fbfbfb;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 20px;
  color: #000;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  margin-left: 35px;
  margin-top: 10px;
  padding: 15px 25px;
}

.user-msg-box p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
}

.support-inner h2 {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
}
.support-msg-box {
  padding: 20px 10px;
  height: 55vh;
  overflow-y: scroll;
}
.user-box {
  margin-bottom: 15px;
}

.send-box {
  bottom: 0;
  position: absolute;
  width: 95%;
}
.send-feild {
  background: #fbfbfb !important;
  border: none !important;
  padding: 15px !important;
}

.send-box input:focus {
  box-shadow: none;
}

.dash-graph {
  border: 0.25px solid #6a6d60;
  background: #fff;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.05);
  padding: 25px 25px;
}

.toggle-icon {
  position: fixed;
  left: 245px;
  border: 2px solid #fff;
  padding: 5px 6px;
  background: #071523;
  filter: drop-shadow(0px 6px 10px rgba(151, 151, 151, 0.47));
  width: 40px;
  height: 40px;
  top: 70px;
  display: flex;
  justify-content: center;
  z-index: 1;
  border-radius: 62px;
  align-items: center;
  cursor: pointer;
}
.toggle-icon img {
  width: 20px;
  filter: invert(1);
}
.left-side.hide aside .side-menu a p {
  display: none;
}
.left-side.hide aside {
  width: 100px;
}
.right-side-cmn.hide {
  width: calc(100% - 100px);
  /* background: #eff8f3; */
}

.left-side.hide .toggle-icon {
  transform: rotateY(180deg);
  left: 78px;
}

.left-side.hide .side-menu a {
  justify-content: center;
}
.right-side-cmn.hide .next-btn-fix {
  width: calc(100% - 100px);
}

.user-profile-main figure {
  margin: 0;
}

.user-profile-main img {
  width: 150px;
  /* border-radius: 79px; */
  object-fit: cover;
  height: 150px;
}

.user-profile-main h2 {
  padding-bottom: 6px;
  margin: 0;
  color: rgb(0 0 0 / 100%);
  font-size: 20px;
  font-weight: 700;
}
.user-profile-main p {
  margin: 0;
  font-size: 14px;
  padding: 2px 0;
  font-weight: 400;
  color: #40413a;
}

.user-profile-main figcaption {
  margin-left: 20px;
}

.logo-side .small {
  display: none;
}
.left-side.hide .logo-side .small {
  display: block;
}
.left-side.hide .logo-side .big {
  display: none;
}

.user-profile-main {
  padding: 20px 15px;
  background-color: #fff;
  border: 0.25px solid #6a6d60;
  box-shadow: 0px 9px 14px 0px rgba(0, 0, 0, 0.05);
}

.send-box button img {
  transform: rotatey(180deg);
  filter: invert(1);
}
.logo-side .small {
  width: 40px;
}

.user-profile-main p span {
  font-weight: 700;
  color: rgba(7, 21, 35, 0.7);
}

.user-profile-all {
  border: 0.25px solid #d9d9d9;
  background: #fff;
  padding: 30px 50px;
  margin-bottom: 30px;
}

.user-profile-all h3 {
  text-align: center;
  color: #6a6d6f;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
}
.user-profile-all span {
  font-weight: 700;
}
.dash-graph .gender-chart {
  height: 400px !important;
  margin: auto;
  max-width: 400px !important;
}

.product-overview-box p {
  background: linear-gradient(180deg, #1ba97d 0%, #118d67 100%);
  box-shadow: 0px 10px 15px 0px rgba(22, 155, 114, 0.4);
  padding: 10px 10px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 4px;

  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 600;
}

.admin-box-select label {
  color: #6a6d60;
  font-size: 13px;
  font-weight: 700;
}

.dash-graph h6 {
  color: #6a6d60;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: -0.4px;
}
.customer-form.profile h2 {
  font-size: 18px;
  font-weight: 600;
}

.property-name-show {
  width: fit-content;
  display: flex;
  align-items: center;

  padding: 10px 12px;
  border-radius: 10px;
  background: linear-gradient(180deg, #6c9459 0%, #39562e 100%);
  box-shadow: 0px 5px 15px 0px rgba(27, 169, 125, 0.2);
  margin-top: 15px;
}
.property-name-show img {
  width: 65px;
  border-radius: 10px;
  height: 64px;
  margin-right: 10px;
}
.property-name-show h2 {
  color: #fff !important;

  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}
.property-name-show h3 {
  color: #fff;

  font-size: 14px;
  font-style: normal;
  opacity: 0.7;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.56px;
}

.property-main h2 {
  color: #071523;
  text-transform: uppercase;

  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.3px;
}

.property-main {
  margin-top: 35px;
  margin-left: 12px;
}

.dashboard-items.profile .dash-inner-boxes {
  margin-top: 10px;
}

.property-name-show h3 {
  margin: 0;
  padding: 0;
  border: 0;
}

.assign {
  border: 1px solid #c1dece;
  padding: 17px 10px !important;
  font-size: 13px !important;
  text-transform: uppercase;
}
.logo img {
  width: 150px;
}

.dash-inner-boxes.vendor {
  margin: 0;
}

.status-box h2 {
  margin: 0;
  color: #fff;
}
.status-box {
  background: linear-gradient(180deg, #1ba97d 0%, #118d67 100%);
  box-shadow: 0px 10px 15px 0px rgba(22, 155, 114, 0.4);
  width: 200px;
  border-radius: 8px;
  padding: 9px 18px;
  margin-top: 20px;
  margin-right: 10px;
}
.status-box p {
  color: #fff;
}

.add-btn-category {
  color: #fff;
  border: 1px solid #071523;
  background: #071523;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 17%);
  width: 97px;
  font-size: 14px;
  height: 36px;
  border-radius: 0;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.change-pass {
  height: 100%;
  margin-top: 30px;
}

.property-name-show.view h2 {
  margin: 0;
}

.bar_active .analytics-icon path {
  fill: #5f6368 !important;
}

a.menu-btn.bar_active path {
  fill: #4318ff !important;
}

.analytics-icon path {
  fill: #000 !important;
}

.property-name-show.customer {
  background: none;
  box-shadow: none;
}

.property-name-show.customer h2 {
  color: rgba(7, 21, 35, 0.7) !important;
  font-weight: 600;
}

.property-name-show.customer h3 {
  font-weight: 600;
  color: #979797;
}

.property-name-show.customer img {
  width: 130px;
  height: 115px;
}

.dash-inner-boxes.small {
  height: 100px !important;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.customer-form-new.card h2 {
  color: #071523;

  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.4px;
}

.user-profile-main h3 {
  color: #071523;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 20px;
}

.admin-box-select select:focus {
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.property-name-show.view {
  background: none;
  box-shadow: none;
}
.property-name-show.view h2 {
  color: #3a7563 !important;

  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
}

.menu-btn.bar_active .dash-only path {
  stroke: none !important;
  fill: #5f6368 !important;
}

.table-cmn.manager img {
  width: 60px;
  height: 60px;
}
.table-cmn td {
  display: table-cell;
  vertical-align: middle;
}

.send-box .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: #b9b5ab;
  border: 0;
  border-radius: var(--bs-border-radius);
}

.support-msg-box {
  -ms-overflow-style: none !important; /* for Internet Explorer, Edge */
  scrollbar-width: none !important; /* for Firefox */
  overflow-y: scroll;
}

.support-msg-box::-webkit-scrollbar {
  display: none !important; /* for Chrome, Safari, and Opera */
}

/* Profile view css  start  */

.user-profile-main hr {
  margin: 30px 0px;
}

.profile-hours {
  display: flex;
  justify-content: space-between;
}

.dlt-ac-btn a {
  border: 1px solid #b9b5ab;
  background: #b9b5ab;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 14%);
  color: #fff;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  padding: 11px 33px;
}

.user-profile-main figcaption input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.user-profile-main figcaption .form-check-input {
  height: 22px;
}

.user-profile-main figcaption .form-switch .form-check-input {
  width: 3em;
  border: var(--bs-border-width) solid #b9b5ab;
}

.user-profile-main figcaption .form-check-input:checked {
  background-color: #b9b5ab;
}

.user-profile-main figcaption .form-switch .form-check-input:focus {
  filter: grayscale(1);
}
/* Profile view css End  */

.default-links ul {
  padding: 0;
  list-style: none;
}
.default-links ul li {
  display: flex;
  border-bottom: 1px solid #b9b5ab6e;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
}
.default-links ul li h6 {
  color: #071523;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
}
.default-links ul li p {
  font-size: 13px;
}

.profile-add-img {
  width: 100% !important;
}

.active-default-links ul {
  padding: 0;
  list-style: none;
}
.active-default-links ul li {
  border-bottom: 1px solid #b9b5ab6e;
  justify-content: space-between;
  padding: 8px 0px;
}
.active-default-links ul li h6 {
  color: rgba(7, 21, 35, 0.7);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 8px;
}
.active-default-links ul li p {
  text-align: end;
  font-size: 13px;
}

.cmn-btn a svg {
  height: 18px;
  width: 18px;
  margin-right: 12px;
}

.cmn-btn a {
  border: 1px solid #071523;
  background: #071523;
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 33px;
}

.theme-colr-btn {
  background: #979797 !important;
  color: #fff !important;
}

.common-colr-btn {
  background: #071523 !important;
  color: #fff !important;
}

.select-box {
  display: flex;
  justify-content: center;
}

.view-chat a {
  border: 1px solid #b9b5ab;
  background: #b9b5ab;
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 11px 33px;
}

.modal-close {
  border: 1px solid #b9b5ab !important;
  background: #b9b5ab !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 13px !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-decoration: none !important;
  padding: 11px 33px !important;
}

.modal-open-view {
  border: 1px solid #b9b5ab !important;
  background: #071523 !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 13px !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-decoration: none !important;
  padding: 11px 33px !important;
}

.no-conversations {
  background: #eaeaea;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-none {
  border: none !important;
}

.chat-box-select select {
  border-radius: 0;
  color: #6a6d60;
  font-weight: 600;
  font-size: 14px;
  height: 45px;
  letter-spacing: -0.3px;
  line-height: 24px;
  width: 350px;
  padding-left: 20px;
}

.sign-banner {
  background-image: url("../Images/right-banner-login.png");
  height: 100vh;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-banner img {
  width: 330px;
}

.login-box-inner-wrap p {
  color: #a3aed0;
}

.form-check-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px !important;
}
.form-set input::placeholder {
  color: #a3aed0;
  font-size: 13px;
}
.form-check-box label {
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  color: #2b3674;
}

.heading-top p {
  color: #707eae;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 5px;
}

.user-side-pro img {
  width: 60px;
}

.user-side-pro {
  border-radius: 30px;
  background: var(--Secondary-Primary-White, #fff);
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
  padding: 4px 8px;
}

.bell-icon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.user-pro-search {
  /* margin-left: 10px; */
}

.user-pro-search input {
  border-radius: 49px;
  background: #f4f7fe;
  border: 0;
  padding: 10px 20px;
  font-size: 14px;
}

.user-pro-search input[type="search"] {
  background-image: url("../Images/search-icon.svg");
  background-position: 18px 18px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
}

.user-pro-ulpd a {
  border-radius: 30px;
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
  text-decoration: none;
  color: #fff;
  background: #d200a5;
  padding: 10px 14px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.notFoundButton a {
  border-radius: 30px;
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
  text-decoration: none;
  color: #fff;
  background: #d200a5;
  padding: 10px 14px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  gap: 8px;
}

.action-box {
  display: flex;
  font-family: "DM Sans", sans-serif;
  border-radius: 20px;
  background: #fff;
  padding: 8px 12px;
  gap: 15px;
}

.action-graph {
  background: #f4f7fe;
  padding: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.action-graph-icon p {
  color: #a3aed0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.28px;
  margin-bottom: 0px;
}

.action-graph-icon h2 {
  color: #2b3674;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.48px;
  margin-top: 5px;
}

.table-box-cmn {
  border-radius: 20px;
  background: var(--Secondary-Primary-White, #fff);
  margin-top: 30px;
  padding-bottom: 10px;
}

.table-heading h2 {
  color: #2b3674;
  font-size: 24px;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  letter-spacing: -0.48px;
  margin-bottom: 0;
}

.logo-side {
  border-bottom: 1px solid #f4f7fe;
}

.add-list-icon {
  border-radius: 12px;
  background: #d200a5;
}

.add-list-icon {
  border-radius: 12px;
  background: #d200a5;
  width: 100px;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}


.add-list-icon-School {
  border-radius: 12px;
  background: #d200a5;
  width: 100px;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.customer-form-new {
  border-radius: 20px !important;
  background: #fff !important;
  border: 0 !important;
}

th span {
  position: relative;
  cursor: pointer;
}


.download-button {
  text-decoration: none;
  color: #fff;

}

th span::after {
  /* background-image: url("../Images/th-arrow.svg"); */
  content: "";
  position: absolute;
  left: 120%;
  color: #999;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  z-index: 999;
  top: 5px;
}

th span.asc::after {
  content: "";
  background-image: url("../Images/th-arrow.svg");
}

th span.desc::after {
  content: "";
  background-image: url("../Images/th-arrow.svg");
}

th span:hover::after {
  color: #333;
}

.active-icon {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
}

.faq-accordon-sec .accordion-header p {
  display: flex;
  flex-direction: column;
  margin: 0;
  color: var(--Secondary-Grey-600, #a3aed0);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.28px;
  gap: 5px;
}

.faq-accordon-sec .accordion-header p span {
  color: #2b3674;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%; /* 16px */
  letter-spacing: -0.32px;
}

.faq-accordon-sec .accordion-item {
  margin-bottom: 10px;
  border-radius: 10px !important;
  border: 0 !important;
}

.faq-accordon-sec .accordion-button {
  border-radius: 16px !important;
  background: var(--Secondary-Primary-White, #fff);
  box-shadow: 0px 18px 40px 0px rgba(112, 144, 176, 0.12);
  border: none !important;
}

.faq-accordon-sec .accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: #fff;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.faq-accordon-sec .accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: none !important;
}

.manage-co-top-tab .top-head h2 {
  color: #2b3674;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
}

.manage-co-top-tab .top-head h2 span {
  color: #4318ff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
  margin-left: 10px;
}

.manage-co-top-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage-tabs-card {
  border-radius: 20px;
  background: var(--Secondary-Primary-White, #fff);
  padding: 15px;
  margin-bottom: 20px;
  font-family: "Raleway", sans-serif !important;
}

.card-img-box {
  display: flex;
  justify-content: center;
}

.card-img-box img {
  width: 100%;
  border-radius: 20px;
}

.details-box-top h3 {
  color: var(--Secondary-Dark-Grey-900, #1b2559);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.36px;
  margin-bottom: 0;
}

.details-box-top p {
  color: var(--Secondary-Grey-600, #a3aed0);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
  margin-bottom: 0;
}

.details-box-top {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.card-box-deta {
  width: 70%;
}

.card-box-icon {
  width: 30%;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.details-box-top .like-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.details-box-bottom p {
  color: var(--Primary-Purple-Blue-500---Primary, #4318ff);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.28px;
  margin-bottom: 0;
}

.details-box-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.details-box-bottom a {
  border-radius: 20px;
  color: var(--Secondary-Primary-White, #fff);
  background: #11047a;
  text-decoration: none;
  padding: 8px 30px;
  font-size: 14px;
}

.manage-tabs-viewbox {
  margin-top: 20px;
}

.manage-co-top-tab .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #4318ff;
  background-color: #ffffff;
  border-radius: 30px;
}

.manage-co-top-tab .nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  color: #4318ff;
  text-align: center;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.28px;
}

.top-video-box {
  border-radius: 20px;
  background: var(--Secondary-Primary-White, #fff);
  padding: 20px;
}

.top-video-box h2 {
  color: var(--Secondary-Dark-Grey-900, #1b2559);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.4px;
}

.top-video-icon {
  display: flex !important;
  align-items: center !important;
  gap: 4px !important;
}

.list-progress .progress-bar {
  background-color: #4318ff;
  height: 10px;
}
.list-progress .progress {
  height: 10px;
}

.comn-modal-set.modal .input-set {
  border-radius: 16px;
  border: 1px solid var(--Secondary-Grey-500, #e0e5f2);
  height: 50px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%; /* 14px */
  letter-spacing: -0.28px;
}

.comn-modal-set.modal label {
  color: #2b3674;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.28px;
}

.comn-modal-set.modal textarea {
  border-radius: 16px;
  border: 1px solid var(--Secondary-Grey-500, #e0e5f2);
  color: #000;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.28px;
  resize: none;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: none !important;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
}
.table-box-cmn .table-responsive {
  overflow-y: scroll;
}
.comn-modal-set.modal select {
  border-radius: 16px;
  border: 1px solid var(--Secondary-Grey-500, #e0e5f2);
  height: 50px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.28px;
  appearance: none !important;
}

.upld-vd-box {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.upld-img-side {
  border-radius: 13px;
  border: 1px dashed var(--Secondary-Grey-500, #e0e5f2);
  background: var(--Secondary-Grey-100, #fafcfe);
  padding: 20px;
  text-align: center;
  width: 300px;
  position: relative;
}

.upld-img-side input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.upld-img-side svg {
  width: 70px;
}

.upld-img-side .upld-text h3 {
  color: var(--Primary-Purple-Blue-500---Primary, #4318ff);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.4px;
}

.upld-img-side .upld-text p {
  color: var(--Secondary-Dark-Grey-500, #8f9bba);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.24px;
}
.upld-btn-side h2 {
  color: #2b3674;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
}

.upld-btn-side p {
  color: var(--Secondary-Grey-600, #a3aed0);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.32px;
}

.upld-btn-side a {
  background-color: #4318ff;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  border-radius: 10px;
}

.comn-modal-set .modal-body {
  padding: 10px 50px;
}

.upd-vd-more {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin-top: 30px;
}

.upd-vd-more p {
  color: #2b3674;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.28px;
  margin-bottom: 0px;
}

.top-video-icon img {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  object-fit: cover;
}

.upd-vd-more a {
  border-radius: 12px;
  background: var(--Secondary-Grey-300, #f4f7fe);
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
}

.modal-f-btn {
  background: #d200a5 !important;
  border: 0 !important;
  /* padding: 10px 0px !important; */
  border-radius: 50px !important;
}

.comn-modal-set .modal-title {
  margin-bottom: 0;
  color: #2b3674;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
  padding-left: 30px;
}

.back-page-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.back-page-title a {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 10px;
}

.school-profile-detail {
  border-radius: 20px;
  font-family: "DM Sans", sans-serif;
  background: var(--Secondary-Primary-White, #fff);
  padding: 10px;
  text-align: center;
}

.qr-code img {
  width: 200px;
  border-radius: 10px;
  box-shadow: 0 1px 4px 0 #00000040;
  height: 200px;
  object-fit: cover;
}

.school-profile-detail p {
  color: var(--Secondary-Grey-600, #a3aed0);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.28px;
}

.heavy-text {
  color: #2b3674 !important;
  text-align: center;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  letter-spacing: -0.4px !important;
}

.three-detail {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin: 15px 0px;
}

.info-about-school .heavy-text {
  color: #2b3674;
  text-align: center;
  font-size: 22px !important;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.48px;
}

.action-school-btns {
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 30px;
}
.action-school-btns a {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  width: 170px;
  border-radius: 10px;
  text-decoration: none;
  color: #4318ff;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.28px;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-center-text {
  color: var(--Secondary-Grey-600, #a3aed0);
  text-align: center;

  font-size: 14px;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.28px;
  margin-bottom: 8px;
}

.heading-top span {
  color: #4318ff;
  margin-left: 10px;
}

.teacher-search-history {
  border-radius: 20px;
  background: var(--Secondary-Primary-White, #fff);
  padding: 15px;
  /* text-align: center; */
  margin-top: 30px;
}

.teacher-search-history h2 {
  color: #2b3674;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
}
.search-history-list h4 {
  color: #2b3674;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.28px;
}

.search-history-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.search-history-list p {
  color: #a3aed0;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.28px;
}

.viewing-h-pro img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 6px;
}

.viewing-h-pro {
  display: flex;
  gap: 10px;
  align-items: center;
}
.viewing-h-pro h5 {
  color: var(--Secondary-Grey-900, #2b3674);
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.32px;
  margin-bottom: 0;
}

.search-history-list.collect-box {
  border-radius: 16px;
  background: var(--Secondary-Primary-White, #fff);
  box-shadow: 0px 18px 40px 0px rgba(112, 144, 176, 0.12);
  padding: 20px;
}

a.search-history-list.collect-box {
  text-decoration: none;
}

.display-table {
  display: table !important;
}
.table-cell {
  display: table-cell !important;
}

.top-card-contin {
  display: flex;
  gap: 20px;
}

.top-card-contin .action-box {
  width: 100%;
}

.action-graph.gradi-circle {
  background: linear-gradient(90deg, #4481eb 0%, #04befe 100%);
}

.single-vid-history-card {
  border-radius: 20px;
  background: #fff;
  padding: 25px;
}

.single-vid-title h2 {
  color: #2b3674;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.32px;
  margin-bottom: 0px;
}

.single-vid-title p {
  color: #a3aed0;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.single-vid-btm {
  display: flex;
  gap: 30px;
}

.single-vid-btm p {
  margin-bottom: 0;
  color: #2b3674;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.28px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.single-vid-card {
  border-radius: 20px;
  background: #fff;
  padding: 24px;
  color: #2b3674;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.36px;
  position: relative;
}

.single-vid-list-tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.single-vid-list-tags .form-check {
  align-items: center;
  display: flex;
  gap: 10px;
}
.error {
  color: red;
}

button.modal-close-btn {
  background: #2b3674 !important;
  border: 0 !important;
  padding: 10px 40px !important;
  border-radius: 20px !important;
}

.suspend-text {
  color: var(--Secondary-Grey-600, #a3aed0);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.28px;
  margin-bottom: 8px;
}

.data-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.table-cmn tbody {
  position: relative;
}

.table-cmn tbody .data-not-found p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.chk-space {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.chk-space label {
  margin: 0;
}

.reset-pass {
  box-shadow: 0px 1px 4px 0px #00000040;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  background: #fff;
  letter-spacing: -0.02em;
  text-align: center;
  border: none;
  padding: 10px 30px;
  color: #4318ff;
  border-radius: 10px;
}

.collections-list-pro {
  height: 320px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  padding: 10px;
}

.collections-list-pro::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.viewing-history-list-scrol {
  height: 260px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  padding: 10px;
}

.viewing-history-list-scrol::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.search-history-list-pro {
  height: 260px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  padding: 10px;
}

.search-history-list-pro::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}
/* .all-students-list{
  height: 260px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  padding: 10px;
}

.all-students-list::-webkit-scrollbar { 
  width: 0 !important;
  display: none; 
} */

.data-not-found-cmn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.ad-arrow {
  background-image: url("../../Assets/Images/arrow-down.svg") !important;
  background-repeat: no-repeat;
  appearance: none;
  padding-right: 40px !important;
  background-position: right 10% bottom 49%;
  background-color: #fff;
  border: 0;
  padding-left: 30px;
}

.ad-arrow:focus{
  outline: 0;
}



.manage-con-tabs select {
  border: 0;
  padding: 10px 22px;
  background-color: #fff !important;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.28px;
}


.top-video-box select {
  border: 0;
  padding: 10px 22px;
  background-color: #fff !important;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.28px;
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
  border: 1px solid #d9d9d9;
}

.top-video-box select:focus-visible {
  outline: none !important;
}

.manage-con-tabs select:focus-visible {
  outline: none !important;
}

.faq-dlt-btn {
  background: #ff5f5f;
  color: #fff;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 14px;
}

.create-category-upld {
  background-color: #4318ff;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  border-radius: 10px;
}

.form-set.d-unset {
  display: unset !important;
  height: auto !important;
}

.date-range-set {
  position: absolute;
  left: -166px;
  right: 0;
  margin: auto;
  top: 42px;
}
.date-range-set .reset-btns {
  border-radius: 20px;
  color: var(--Secondary-Primary-White, #fff);
  background: #11047a;
  text-decoration: none;
  padding: 8px 30px;
  border: none;
  font-size: 14px;
  margin-right: 21px;
}

.single-vid-card > div {
  width: 100% !important;
  height: 100% ;
}

.single-vid-btm p svg path {
  fill: #01b574;
}

.video-play-mute {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  background: #d92cb500;
  width: 100%;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  margin-bottom: -50px;
  z-index: 999;
}

.video-play-mute button {
  background: none;
  border: 0;
}

.video-mute-btn svg {
  width: 20px !important;
  height: 20px !important;
}
.video-play-mute input[type="range"] {
  accent-color: #d200a5;
}

.video-play-mute button svg {
  width: 20px !important;
  height: 20px !important;
}

.redImage path {
  fill: #ff0000 !important;
}

.dasb_text {
  text-align: center;
  padding: 20px;
}

.dasb_text h1 {
  font-size: 24px;
}

.dasb_text h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 57px;
  font-style: normal;
  text-align: center;
  margin: 0px;
  color: #414141;
}

.dasb_text_p {
  font-style: normal;
  font-weight: 500;
  font-size: 12.0026px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 10px;
}

.dasb_textlastp {
  font-style: normal;
  font-weight: 400;
  font-size: 11.2981px;
  line-height: 20px;
  /* identical to box height */
  margin: 0px;
  text-align: center;
  color: #9d9fb1;
}

.bglight {
  background: #eefdfe;

  border-radius: 16.6227px;
}

.bgbrown {
  background: #72ef361f;

  border-radius: 16.6227px;
}
.bgblue {
  background: #f3e9ff;

  border-radius: 16.6227px;
}

.bgyelow {
  background-color: #fef5ef;
}
.black {
  color: #414141;
}

.title_text {
  font-style: normal;
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #2b3674;
}

.card-body th {
  color: #9da6ba !important;
  /* font-family: Inter !important; */
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-body td {
  color: #414141;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main-dashboard .card {
  border-radius: 10px;
  background: var(--Color, #fff);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  border: none !important;
  border-radius: var(--bs-card-border-radius);
}

.dropdown_custom button {
  border-radius: 11.082px;
  border: 1.108px solid #dbd8fc;
  background: var(--Color, #fff);
  padding: 10px 20px !important;
  color: #414141 !important;
  font-size: 13.298px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dropdown_custom button::after {
  display: inline-block;
  margin-left: 1.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.graph-tabs-days .nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  color: #414141;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.graph-tabs-days .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
  border-radius: 20.057px;
  background: #5c5c5c;
  border: 0 !important;
}

.videoRepeate {
  border: 1px solid var(--Secondary-Grey-500, #e0e5f2);
  padding: 20px;
  border-radius: 10px;
}

.notify-edit-btn {
  padding: 0;
  color: #2b3674;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: left;
  background: #fff;
  border: 0;
}

.notify-edit-btn.disabled {
  padding: 0;
  color: #2b367469;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: left;
  background: #fff;
  border: 0;
}

.content-btn button span {
  margin-right: 15px;
}
.content-btn button {
  width: 140px;
  padding: 10px 0 !important;
}

.table-pagina-set {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.table-pagina-set .active > .page-link,
.page-link.active {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #d200a5 !important;
  border-color: #d200a5;
  color: #fff !important;
}

.table-pagina-set .page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: #d200a5 !important;
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid
    var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#chart > div {
  width: 100% !important;
}

#chart .apexcharts-canvas {
  width: 100% !important;
}

#chart .apexcharts-canvas svg {
  width: 100% !important;
}

#chart .apexcharts-canvas {
  width: 100% !important;
}

.upld-btn-side .spinner-border {
  width: 20px;
  height: 20px;
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -5px;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
  margin-left: 10px;
}

.tab_le_dealer {
  height: 320px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  padding: 10px;
}
.tab_le_dealer::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.details-chart > div {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.details-chart > div {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.search-history-list a {
  text-decoration: none;
}

.school-profile-detail .qr-code {
  margin-bottom: 20px;
}


.upld-csv-btn {
  width: 170px !important;
}
.upld-csv-btn-download {
  width: 125px !important;
}


/* .ad-scroll-table  table {
  width: 100% !important;
  height: 50vh !important;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
} */

.ad-scroll-table .table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  height: 325px;
  scrollbar-width: thin;
}

.pop-cancel-btn {
  padding: 10px 40px !important;
}


.custom-tooltip{
  background-color: #fff;
  padding: 10px;
  display: flex;
  gap: 10px;
  box-shadow: 0 .5rem 1.2rem rgba(0, 0, 0, .2);
}
.ad-height-vid > div {
  height: 630px !important;
}


.ad-height-vid > div video{
  object-fit: cover;
}

.vid-loading {
  position: relative;
}

.react-datepicker-wrapper {
  width: 100%;
}

.notify-multi-set {
  gap: 20px;
}

.notify-multi-set  .notify-set p {
  margin: 0;
  color: #2b3674;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.28px;
}

.notify-multi-set  .add-list-icon {
  width: 130px !important;
}

.no-border-row{
  border-style: none ;
  border-color: #fff;
}



.table-cmn-tr-head {
  padding: 12px 12px !important;
  color: #a3aed0 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
  text-align: left !important;
  border-style: solid !important;
  border-color: #e9edf7 !important;
}




.table-cmn-tr-cell  {
  padding: 8px 12px !important;
  color: #2b3674 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: -0.3px !important;
  text-align: left !important;
}


.table-cmn-tr-td-anchor {
  color: #2b3674;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.active-icon > div{
  align-items: center!important;
  display: flex!important;
  gap: 10px!important;
}


.css-73fo9o-HEADER_CELL_CONTAINER_STYLE-HeaderCell {
  z-index: 0 !important;
}


.css-dfl36u{
  z-index: 0 !important;

}

.admin-box-main .data-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
}


.admin-detail-scroll {
  height: 695px;
  scrollbar-width: none;
}

.single-vid-text p {
  word-break: break-word;
}

.next-scroll {
  height: 350px !important;
  overflow-y: scroll;
  scrollbar-width: none;
}